import { NgModule } from '@angular/core';
import { EditorBlockComponent } from './editor-block/editor-block.component';
import {NgIf} from "@angular/common";

@NgModule({
  declarations: [EditorBlockComponent],
    imports: [
        NgIf
    ],
  exports: [EditorBlockComponent],
})
export class EditorBlockModule {}
