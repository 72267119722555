import { Injectable } from '@angular/core';
import { DataUnitsService } from './data-units.service';
import {
  RecUserServiceMeta,
  RecUserServices,
} from '@isp-sc/shared/segments/data/common';
import { DataBase, FilterInfo } from '@og_soft/data-base';
import { PhoneNumberPipe } from '@og_soft/phone-number';
import { PopupOption } from '@isp-sc/shared/ui';

@Injectable({
  providedIn: 'root',
})
export class DataUserServicesService extends DataBase<
  RecUserServices,
  RecUserServiceMeta
> {
  protected getAllUrl(): string {
    return 'user-service' as const;
  }

  public static saIdentGet(
    saData: RecUserServices,
    format = true
  ): string | undefined {
    for (let i = 0; saData.params && i < saData.params.length; i++) {
      const parInfo = saData.params[i];
      if (parInfo.saIdent && parInfo.value) {
        return format
          ? parInfo.type === 'PHONE'
            ? PhoneNumberPipe.prototype.transform('+' + parInfo.value)
            : parInfo.value
          : parInfo.value;
      }
    }
    return DataUnitsService.shortenAddress(saData.unitAddress);
  }

  override tranformOptions(row: RecUserServices): PopupOption {
    const saIdent = DataUserServicesService.saIdentGet(row, true);
    return {
      id: row.saBaseId.toString(),
      name: row.saName + (saIdent ? ' (' + saIdent + ')' : ''),
      icon: row.saStateId === 2 ? 'clear' : '',
      sorter: row.saStateId === 2 ? 0 : 1,
    };
  }

  override metaFetchFilterInfo(meta: RecUserServiceMeta): FilterInfo[] {
    if (meta) {
      const info: FilterInfo[] = [];
      if (meta.id) {
        let name = meta.name;
        const ident = meta.parIdent;
        if (ident) {
          name += ', ' + ident;
        }
        info.push({
          label: $localize`:@@DataUserServices.filterInfo.node.label:Zařízení`,
          filterNames: ['nodeId'],
          value: name,
        });
      }
      return info;
    }

    return super.metaFetchFilterInfo(meta);
  }
}
