import { Component, ViewChildren, OnInit, QueryList } from '@angular/core';
import { ScNavComponent } from '../../navigation/navigation/sc-nav.component';
import { DatePipe } from '@angular/common';
import { MatMenuTrigger } from '@angular/material/menu';
import { UserDataUnicreditService } from '@isp-sc/shared/segments/user/data-access';
import { ProcessRedirectService } from '@isp-sc/shared/segments/process/data-access';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import { DataProcessService } from '@isp-sc/shared/segments/params/data-access';
import {
  DataProcessNotesService,
  DataProcessTypesService,
} from '@isp-sc/shared/segments/mango-processes/data-access';
import { ProcessType } from '@isp-sc/shared/segments/mango-processes/common';
import { Process } from '@isp-sc/shared/segments/params/common';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'sc-main-toolbar',
  templateUrl: './sc-main-toolbar.component.html',
  styleUrls: ['./sc-main-toolbar.component.scss'],
})
export class ScMainToolbarComponent implements OnInit {
  public unresolvedProcesses = 0;
  public processTypes?: ProcessType[] = [];
  public activeProcesses: Process[] = [];
  public unicreditBalance = 0;
  public ucVarSymbol: string | null = null;

  @ViewChildren(MatMenuTrigger) menuTriggers?: QueryList<MatMenuTrigger>;

  openedMenu: MatMenuTrigger | undefined;

  constructor(
    public session: SessionService,
    public scnav: ScNavComponent,
    public processService: DataProcessService,
    public dataTypesService: DataProcessTypesService,
    private dataUnicredit: UserDataUnicreditService,
    private historyService: DataProcessNotesService,
    private datePipe: DatePipe,
    public processRedirectService: ProcessRedirectService
  ) {
    this.session.loginChanged().subscribe((next) => {
      if (next) {
        this.updateUnresolvedProcesses();
      }
    });
    // Když provedu nějakou změnu procesu, ikonu nevyřízených procesů
    this.processService.getProcessChanged().subscribe((next) => {
      if (next) {
        this.updateUnresolvedProcesses();
      }
    });

    this.processService.getall({ hasJob: '1' }, 60).subscribe((next) => {
      this.activeProcesses = next.data;
      this.activeProcesses.forEach((process) => {
        this.historyService
          .getall({ id: process.id, 'types[]': [13], limit: 1 }, 60)
          .subscribe((notes) => {
            process.notes = notes.data;
          });
      });
    });

    this.dataTypesService.getTypesForInsert().subscribe((next) => {
      this.processTypes = next.data;
    });
  }

  get currencySymbol(): string {
    return this.session.locDefaults.currencyCode;
  }

  ngOnInit(): void {
    // Data pro unicredit - jen pokud má zákazník aktvní službu unicredit (předplatné)
    // if (this.widgetVisible("unicredit")) {
    this.dataUnicredit.getone(this.session.user?.id).subscribe((d) => {
      this.unicreditBalance = d.ucBalance;
      this.ucVarSymbol = d.ucVarSymbol;
      /*
        if (this.unicreditBalance < 1) {
          this.priorityUc = 10;
        } else {
          this.priorityUc = 2;
        }
        this.relayoutDashboard();
         */
    });
    // }
  }

  private updateUnresolvedProcesses(): void {
    this.processService.getall({ hasJob: '1' }).subscribe((next) => {
      this.unresolvedProcesses = next.data.length;
    });
  }

  public transFormLastActivity(process: Process): string {
    const startText = $localize`:@@ScMainToolbar.process.lastActivity.start:Změněno`;
    if (process.lastActivityObj) {
      const today = new Date();
      if (
        process.lastActivityObj.getDate() === today.getDate() &&
        process.lastActivityObj.getMonth() === today.getMonth() &&
        process.lastActivityObj.getFullYear() === today.getFullYear()
      ) {
        return (
          startText +
          ' ' +
          $localize`:@@ScMainToolbar.process.lastActivity.today:dnes`
        );
      }
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      if (
        process.lastActivityObj.getDate() === yesterday.getDate() &&
        process.lastActivityObj.getMonth() === yesterday.getMonth() &&
        process.lastActivityObj.getFullYear() === yesterday.getFullYear()
      ) {
        return (
          startText +
          ' ' +
          $localize`:@@ScMainToolbar.process.lastActivity.yesterday:včera`
        );
      }
      return startText + ' ' + this.datePipe.transform(process.lastActivityObj);
    } else {
      return '';
    }
  }

  logout(): void {
    this.session.logout();
  }

  onMenuOpen(a: MatMenuTrigger): void {
    this.closeMenus(a);
  }

  menubarButtonMouseOver(trigger: MatMenuTrigger): void {
    // console.log("XXX menubarButtonMouseOver:", trigger);
    if (this.openedMenu) {
      this.closeMenus(trigger);
      trigger.openMenu();
    }
  }

  closeMenus(exceptFor?: MatMenuTrigger): void {
    this.menuTriggers?.forEach((e) => {
      if (e !== exceptFor) {
        e.closeMenu();
      }
    });
    this.openedMenu = exceptFor;
  }

  /* Ten kód je připravený na to, aby v něm šlo udělat chování klasicé
   * menubar - tedy když mám otevřené menu a přihoveruju na otvírák jiného
   * menu, tak se mi zavře první menu a otevře druhé. Ale nejde to
   * v rozumném čase stoprocentně vychytat. (Překáží tam hinty otvíráků
   * a po zavření menu zůstává materiálové vysvícené kolečko pod otvírákem.)
   * Dále by se muselo dodělat použití na mobilu (to by šlo).
   *
   * Kdybychom to chtěli, začátek je k otvírákům přidat události:
              (mouseover)="menubarButtonMouseOver(newProcessTrigger)"
              (mouseover)="menubarButtonMouseOver(processesTrigger)"
              (mouseover)="menubarButtonMouseOver(userTrigger)"
   */

  onMenuClosed(trigger: any): void {
    this.openedMenu = undefined;
    // Tohle mělo tlačítko zbavit vysvíceného kolečka pod ním,
    // když se zavře menu (jiné než se původně otevřelo a otevřené
    // na základě hoveru), ale stejně to nefunguje.
    trigger._element.nativeElement.blur();
    trigger._element.nativeElement.classList.remove('cdk-focused');
    trigger._element.nativeElement.classList.remove('cdk-program-focused');
  }

  openProcess(id: number, typeId: number | null): void {
      this.processRedirectService.openProcess(id, typeId);
  }
}
