import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, Validators } from '@angular/forms';
import { FormHelperService } from '@isp-sc/shared/data-access';
import { PasswordService } from '@isp-sc/shared/segments/auth/data-access';
import { SessionService } from '@isp-sc/shared/segments/session/data-access';
import { MangoFormControl } from '@isp-sc/shared/segments/mango-processes/common';
import { PasswordResetAction } from '@isp-sc/shared/segments/auth/common';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements OnInit {
  public formLogin: FormGroup;
  public formCheck: FormGroup;
  public formPassword: FormGroup;
  public action?: PasswordResetAction; // Příznak, který určí jestli restuji heslo (posílám kód), měním heslo, nebo aktivuji uživatele.
  private code = null;

  constructor(
    public session: SessionService,
    public formHelper: FormHelperService,
    private route: ActivatedRoute,
    private passwordService: PasswordService,
    private router: Router
  ) {
    this.formLogin = new FormGroup({
      email: new MangoFormControl('', [
        /*Validators.required,*/ Validators.email,
      ]),
      login: new MangoFormControl('', [Validators.required]),
    });
    this.formCheck = new FormGroup({
      code: new MangoFormControl('', [
        /*Validators.required*/
      ]),
    });
    this.formPassword = new FormGroup({});

    this.formLogin.get('login')?.disable();
    (this.formLogin.get('login') as MangoFormControl).hide();
  }

  ngOnInit() {
    // Toto by nikdy neměl dělat přihlášený uživatel.
    if (this.session.isLoggedIn()) {
      this.session.logout();
    }

    this.action = this.route.snapshot.queryParamMap.get(
      'action'
    ) as PasswordResetAction;
    const code = this.route.snapshot.queryParamMap.get('passcode');

    // Na změnu hesla není možné přistoupit přímo (v takovém případě není vyplněný code)
    // Pokud toto sapdne do této podmínky, dochází buď ke špatnému přesměrování, nebo se pokoušíme změnit heslo, když není k dispozici kód na změnu hesla.
    if (this.action === PasswordResetAction.Change && !this.code) {
      this.session.message(
        $localize`:@@Password.change.error.message:Neplatný odkaz.`
      );
      this.router.navigate(['/login']);
    }

    if (this.action === PasswordResetAction.Check && !!code) {
      this.checkCode(code.substring(4, 16));
    }
  }

  resetPassword() {
    if (!this.formLogin.controls['email'].value) {
      this.session.message(
        $localize`:@@Password.email.error.message:Prosím, zadejte Vaši e-mailovou adresu, bez ní to nejde.`
      );
      return;
    }

    if (this.formLogin.valid) {
      this.passwordService.checkEmail(this.formLogin.getRawValue()).subscribe(
        (next) => {
          if (
            next === 'CHECK_LOGIN' &&
            !this.formLogin.controls['login'].value
          ) {
            const loginControl = this.formLogin.controls['login'] as MangoFormControl;
            loginControl.enable();
            loginControl.show();
            this.session.message(
              $localize`:@@Password.checkEmail.next.error.message:Zadaná emailová adresa není v systému Mango jedinečná, zadejte prosím ještě login.`
            );
          } else {
            const params = {
              ...this.formLogin.getRawValue(),
              ...{ url: window.origin + '/' },
            };
            //console.log("XXXX calling passwordService with params %o", params);
            this.passwordService.resetPassword(params).subscribe(
              (next) => {
                this.session.message(
                  $localize`:@@Password.change.message:Pokud je zadaný email přiřazen odpovídajícímu účtu byl na něj zaslán kód pro změnu hesla. Zkonrolujte prosím svojí e-mailovou schránku.`
                );
                this.router.navigate(['/password'], {
                  queryParams: { action: 'check' },
                });
                this.action = PasswordResetAction.Check;
              },
              (err) => {
                console.log('Something went wrong: ', err);
                this.session.message(
                  $localize`:@@Password.resetPassword.error.message:Něco se pokazilo.`
                );
              }
            );
          }
        },
        (err) => {
          console.log('Something went wrong: ', err);
          this.session.message(
            $localize`:@@Password.checkEmail.error.message:Něco se pokazilo.`
          );
        }
      );
    } else {
      this.formHelper.markDirty(this.formLogin);
    }
  }

  changePassword() {
    if (this.formPassword.valid) {
      const params = {
        ...this.formPassword.getRawValue(),
        ...{ code: this.code },
      };

      this.passwordService.changePassword(params).subscribe(
        (next) => {
          if (next && Object.prototype.hasOwnProperty.call(next, 'login')) {
            this.session.message(
              $localize`:@@Password.hasOwnProperty.message:Byla provedena změna hesla uživatelskému účtu s loginem: ` +
                next.login
            );
            this.router.navigate(['/login']);
          } else {
            if (next.msg) {
              this.session.message(next.msg);
            } else {
              this.session.message(
                $localize`:@@Password.hasOwnProperty.error.message:Heslo nebylo změněno, zadaný kód je neplatný nebo vypršela jeho časová platnost, nebo je nové heslo stejné jako stávající.`
              );
            }
          }
        },
        (err) => {
          this.session.message(
            $localize`:@@Password.changePassword.error.message:Něco se pokazilo.`
          );
        }
      );
    } else {
      this.formHelper.markDirty(this.formPassword);
    }
  }

  // Slouží jen k včasnému rozpoznání toho, že kód pro obnovu hesla není validní
  // skutečná kontrola se provádí až při samotném odeslání hesla.
  checkCode(code: any) {
    let params: any;
    if (code === null) {
      if (this.formCheck.valid) {
        params = this.formCheck.getRawValue();
        code = params.code;
        if (!code) {
          this.session.message(
            $localize`:@@Password.checkCode.message:Prosím, zadejte platný kód pro obnovu hesla.`
          );
          return;
        }
      } else {
        this.formHelper.markDirty(this.formCheck);
        return;
      }
    } else {
      params = { code: code };
    }

    this.passwordService.checkCodeValidity(params).subscribe(
      (next) => {
        if (next === 'OK') {
          this.code = code;
          this.router.navigate(['/password'], {
            queryParams: { action: 'change' },
          });
          this.action = PasswordResetAction.Change;
        } else {
          this.session.message(
            $localize`:@@Password.checkCodeValidity.validate.message:Obnovovací kód není validní nebo jeho platnost už vypršela.`
          );
        }
      },
      (err) => {
        console.log('Obnovovací kód není validní ', err);
        this.session.message(
          $localize`:@@Password.checkCodeValidity.error.message:Obnovovací kód není validní nebo jeho platnost už vypršela.`
        );
      }
    );
  }
}
