import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { FormGroup } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'mgt-filters',
  template: `
    <div
      *ngIf="filtering"
      [@animateFilters]="
        (isMobile ? 'mobile-' : '') + (filtering ? 'in' : 'out')
      "
      [ngClass]="{
        'forget-table-filters': true,
        'mobile-view': isMobile
      }"
    >
      <form #filters="ngForm" (submit)="onSubmit($event)">
        <div class="filters-header">
          <button
            *ngIf="isMobile"
            mat-button
            (click)="filtering = false; $event.stopPropagation()"
          >
            <mat-icon>clear</mat-icon>
            <span i18n="@@table.filters-header.clear">Zavřít</span>
          </button>
          <h3>
            <span i18n="@@table.filters-header.title">Nastavení filtrů</span>
          </h3>
          <button *ngIf="isMobile" type="submit" mat-button>
            <mat-icon>done</mat-icon>
            <span i18n="@@table.filters-header.done">Použít</span>
          </button>
        </div>

        <div class="filters-contents">
          <ng-content></ng-content>
        </div>

        <div class="forget-table-filters-control" *ngIf="!isMobile">
          <button
            mat-flat-button
            (click)="filtering = false; $event.stopPropagation()"
          >
            <!-- <mat-icon>clear</mat-icon> -->
            <span i18n="@@table.forget-table-filters-control.clear"
              >Zavřít</span
            >
          </button>
          <button mat-flat-button type="submit" color="primary">
            <!-- <mat-icon>done</mat-icon> -->
            <span i18n="@@table.forget-table-filters-control.done">Použít</span>
          </button>
        </div>
      </form>
    </div>
  `,
  styleUrls: ['./forget-table-filters.component.scss'],
  animations: [
    trigger('animateFilters', [
      transition('void=>in,*=>in', [
        style({ height: 0, overflow: 'hidden' }),
        animate('300ms ease-in', style({ height: '*' })),
      ]),
      transition('in=>void,in=>*', [
        style({ height: '*', overflow: 'hidden' }),
        animate('300ms ease-out', style({ height: 0 })),
      ]),
      transition('void=>mobile-in,*=>mobile-in', [
        style({ transform: 'translate3d(100%, 0, 0)' }),
        animate('150ms ease-in', style({ transform: 'translate3d(0, 0, 0)' })),
      ]),
      transition('mobile-in=>void,mobile-in=>*', [
        style({ transform: 'translate3d(0, 0, 0)' }),
        animate(
          '150ms ease-out',
          style({ transform: 'translate3d(100%, 0, 0)' })
        ),
      ]),
    ]),
  ],
})
export class ForgetTableFiltersComponent {
  /**
   * Objekt aktuálně filtruje
   */
  private _filtering = false;
  @Input()
  public get filtering(): boolean {
    return this._filtering;
  }
  public set filtering(value: boolean) {
    if (this._filtering !== value) {
      this._filtering = value;
      if (value) {
        this.setFilterValues({});
      }
    }
  }

  /**
   * FormGroup obsahující všechny itemy pro nastavení filtrů. Zatím (selfCare) uvažujeme o jediné FormGroup.
   */
  @Input()
  public formGroup?: FormGroup | null = null;

  /**
   * Zobrazení na mobilu.
   */
  @Input()
  public isMobile = false;

  /**
   * Event při submitu filtrů.
   */
  @Output()
  applyFilters = new EventEmitter();

  constructor(private deviceDetector: DeviceDetectorService) {
    this.isMobile = deviceDetector.isMobile();
  }

  /**
   * Handler pro obsluhu submitu formuláře.
   * Vyvolá zaregistrovaný event handler a zavře (skryje) filtrovací formulář.
   */
  public onSubmit($event: Event): void {
    // console.log('Form valid:', this.formGroup.valid, ', errors:', this.formGroup.errors);
    if (this.formGroup?.valid) {
      // only if form is valid!
      this.filtering = false;
      this.applyFilters.emit(this.formGroup.value);
    }
    $event.preventDefault();
  }

  public setFilterValues(values?: any): void {
    if (this.formGroup) {
      this.formGroup.reset();
      this.formGroup.patchValue(values);
    }
  }

  /**
   * Přepíná stav filtrování danými filtry. Při zobrazování filtrů dojde k resetu hodnot (nastaví se values).
   * @param values Hodnoty, které se filtrům nastaví při otevření formu na nastavení.
   */
  public toggleFiltering(values: any): void {
    console.log('TOGGLE FILTERING', !this._filtering);
    if (!this._filtering) {
      this.setFilterValues(values);
    }
    this._filtering = !this._filtering;
  }
}
